import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  SectionPageTitle,
  BreadCrumb
} from "../components/Section"
import CallNowSection from "../components/CallNow"
import { StaticImage } from "gatsby-plugin-image"
import TermsConditions from "../components/Content/TermsConditions"

const BackgroundImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      @media (min-width: 1600px) {
        object-position: left center !important;
      }
    }
  }
`
const TermsConditionsPage = ({ location }) => {
  return (
    <Layout location={location} >
      <Seo title="Terms & Conditions - Coast to Coast Carports" description="You can check the terms and conditions carefully before using our metal building installation services. For more details on T&C please call us at (866) 591-5162." />
      
      <BreadCrumb><div className="container  mw-1120"><Link to="/">Home / </Link><span>Terms & Conditions</span></div></BreadCrumb>
      
      <Section pt="215px" pb="60px" xpt="120px" xpb="60px" bgColor="#FAFAFA">
        <BackgroundImage><StaticImage src="../images/featured-articles.png" alt="featured-articles"/></BackgroundImage>
        <div className="container  mw-1120"><SectionPageTitle textAlign="left">Terms & Conditions</SectionPageTitle></div>
      </Section>

      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container mw-1120"><TermsConditions /></div>
      </Section>
      <CallNowSection />
    </Layout>
  )
}

export default TermsConditionsPage
